import { Icon, ListItem, Popover, Typography, Tooltip } from 'design-system'

const PopoverReplyMessage = ({
  open,
  elementRef,
  replyClick,
  copyClick,
  forwardClick,
  forwardDisabled,
  copyDisabled,
  isFile,
  onSave,
  ...props
}) => {
  return (
    <Popover ref={elementRef} open={open} minWidth='118px' {...props}>
      {copyClick && (
        <ListItem onClick={copyClick}>
          <Icon icon='Copy' color='grey.300' />
          <Typography color='grey.300'>Copiar</Typography>
        </ListItem>
      )}
      {replyClick &&
        <Tooltip title={copyDisabled ? 'Não é possível responder essa mensagem' : ''} position='bottom'>
          <ListItem onClick={!copyDisabled ? () => replyClick() : () => { }} disabled={copyDisabled}>
            <Icon icon='Reply' color='grey.300' disabled={copyDisabled} />
            <Typography disabled={copyDisabled} color='grey.300'>Responder</Typography>
          </ListItem>
        </Tooltip>
      }
      <Tooltip title={forwardDisabled ? 'Não é possível encaminhar essa mensagem' : ''} position='bottom'>
        <ListItem
          onClick={!forwardDisabled ? () => forwardClick() : () => { }} disabled={forwardDisabled}>
          <Icon icon='Forward' color='grey.300' disabled={forwardDisabled} />
          <Typography color='grey.300' disabled={forwardDisabled}>Encaminhar</Typography>
        </ListItem>
      </Tooltip>
      {isFile &&
        <ListItem
          onClick={onSave}>
          <Icon icon='Download2' color='grey.300' />
          <Typography color='grey.300'>Baixar</Typography>
        </ListItem>
      }
    </Popover >
  )
}

export default PopoverReplyMessage
