import styled from 'styled-components'

import { Column, Icon, ListItem, Popover, Tooltip } from 'design-system'
import { useClickout } from 'hooks'

const MoreButton = ({ actionsList, tooltip, disabled }) => {
  const { openedState, elementRef, triggerRef } = useClickout(false)

  return (
    <Column>
      <Tooltip position='bottom' title={tooltip}>
        <Base ref={disabled ? null : triggerRef} active={openedState} disabled={disabled}>
          <Icon icon='More' color={disabled ? 'text' : 'primary'} />
        </Base>
      </Tooltip>
      <Popover ref={elementRef} open={openedState} width='170px' top='42px' left='0'>
        {actionsList.map(({ title, action }) => (
          <ListItem onClick={action}>{title}</ListItem>
        ))}
      </Popover>
    </Column>
  )
}

const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  padding: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ active }) =>
    active ? 'rgba(197, 192, 227, 0.3)' : 'rgba(197, 192, 227, 0.1)'};
  &:hover {
    background: rgba(197, 192, 227, 0.3);
  }
`

MoreButton.defaultProps = {
  actionsList: [],
  tooltip: '',
  disabled: false,
}

export default MoreButton
